#site-header {
  background-color: white;
  --site-header-divider: 1px solid var(--color-border-separator);
  border-bottom: var(--site-header-divider);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-l);
  margin-bottom: 30px;
  padding-bottom: 15px;

  @media (min-width: 63.25em) {
    border-bottom: none;
    padding-bottom: 0;
  }

  .site-header__search {
    display: none;

    @media (min-width: 63.25em) {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--color-text);
      img {
        margin-left: 2px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.site-header-logo {
  font-size: 1rem;
  margin: 0;

  img {
    height: 30px;
    width: auto;

    @media (min-width: 40em) {
        height: 55px;
    }
  }
}

.site-header__menu {
  @media (min-width: 63.25em) {
    width: 100%;
  }
}

.site-header__menu .menu-desktop {
  display: none;
  width: 100%;
  margin-top: var(--spacing-m);
  padding-top: var(--spacing-s);
  border-top: var(--site-header-divider);

  @media (min-width: 63.25em) {
    display: block;
  }
}

.site-header__menu .menu-mobile {
  @media (min-width: 63.25em) {
    display: none;
  }
}
